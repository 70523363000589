<template>
  <div v-if="loaded" class="sl_standard_layout">
    <div class="main-container">
      <h2 class="sl__h2 container-header">
        Congratulations!
      </h2>

      <div class="container-body">
        <p class="sl__p">
          Your new services are set up and ready to use.
        </p>

        <br>

        <h3 class="sl__h3">
          Next Steps
        </h3>

        <br>

        <div class="cards-container">
          <standard-horizontal-card
            title="Start or Add a Business"
            description="Start a business or add your existing one-we'll help you maintain and grow it."
            :width="50"
            :image="{ png: cardImages.convertCompany.png, webp: cardImages.convertCompany.webp }"
            @selected="navigateToConvertCompany"
          />
          <standard-horizontal-card
            title="Visit Websites and Email"
            description="Start customizing your new website or visit your new inbox."
            :width="50"
            :image="{ png: cardImages.websites.png, webp: cardImages.websites.webp }"
            @selected="navigateToWebsitesPage"
          />
        </div>
      </div>
    </div>
  </div>
  <ct-centered-spinner v-else />
</template>

<script>
import WebsitesPng       from '@images/illustrations/products/online_presence.png'
import WebsitesWebp      from '@images/illustrations/products/online_presence.webp'
import ConvertCompanyPng from '@images/illustrations/products/agreement.png'
import ConvertCompanyWebp  from '@images/illustrations/products/agreement.webp'
import { mapGetters } from 'vuex'

export default {
  name: 'DomainsFirstEndSlide',
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
    StandardHorizontalCard: () => import('@/components/StagelineV2/cards/StandardHorizontalCard.vue'),
  },
  props: {
    isSlide: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      loaded: false,
      cardImages: {
        convertCompany: {
          png: ConvertCompanyPng,
          webp: ConvertCompanyWebp,
        },
        websites: {
          png: WebsitesPng,
          webp: WebsitesWebp,
        },
      },
    }
  },
  computed: {
    ...mapGetters('stageline', ['company']),
  },
  mounted() {
    this.loaded = true
  },
  methods: {
    async navigateToWebsitesPage() {
      this.loaded = false

      await this.$router.push({
        name: 'websites',
      }).catch(()=>{})
    },

    async navigateToConvertCompany() {
      this.loaded = false

      await this.$router.push({
        name: 'domainsStartCompany',
        params: { companyId: this.company.id },
      }).catch(()=>{})
    },
  },
}
</script>

<style lang="scss" scoped>
.cards-container {
  width: 100%;
  display: inline-flex;
  position: relative;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: 1.5em;
}
</style>
