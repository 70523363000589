var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c("div", { staticClass: "sl_standard_layout" }, [
        _c("div", { staticClass: "main-container" }, [
          _c("h2", { staticClass: "sl__h2 container-header" }, [
            _vm._v("\n      Congratulations!\n    "),
          ]),
          _c("div", { staticClass: "container-body" }, [
            _c("p", { staticClass: "sl__p" }, [
              _vm._v(
                "\n        Your new services are set up and ready to use.\n      "
              ),
            ]),
            _c("br"),
            _c("h3", { staticClass: "sl__h3" }, [
              _vm._v("\n        Next Steps\n      "),
            ]),
            _c("br"),
            _c(
              "div",
              { staticClass: "cards-container" },
              [
                _c("standard-horizontal-card", {
                  attrs: {
                    title: "Start or Add a Business",
                    description:
                      "Start a business or add your existing one-we'll help you maintain and grow it.",
                    width: 50,
                    image: {
                      png: _vm.cardImages.convertCompany.png,
                      webp: _vm.cardImages.convertCompany.webp,
                    },
                  },
                  on: { selected: _vm.navigateToConvertCompany },
                }),
                _c("standard-horizontal-card", {
                  attrs: {
                    title: "Visit Websites and Email",
                    description:
                      "Start customizing your new website or visit your new inbox.",
                    width: 50,
                    image: {
                      png: _vm.cardImages.websites.png,
                      webp: _vm.cardImages.websites.webp,
                    },
                  },
                  on: { selected: _vm.navigateToWebsitesPage },
                }),
              ],
              1
            ),
          ]),
        ]),
      ])
    : _c("ct-centered-spinner")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }